class Cookie {

  cookieContainer: Element | null
  cookieAgree: Element | null
  cookieReject: Element | null
  cookieClose: Element | null
  rejectFlag: string | null
  cookieData: string
  cookieSetFlag: boolean
  cookieExpire: number
  cookieDataList: string[]

  constructor () {
    this.cookieContainer = document.querySelector("#cookie");
    this.cookieAgree = document.querySelector(".cookie-agree");
    this.cookieReject = document.querySelector(".cookie-reject");
    this.cookieClose = document.querySelector(".cookie-close");
    this.rejectFlag = sessionStorage.getItem("rejectFlag");
    this.cookieData = document.cookie;
    this.cookieDataList = this.cookieData.split("; ");
    this.cookieSetFlag = false;
    this.cookieExpire = 31; // Cookieの有効期限（日）
    this.init();
  }

  init(): void {

    for (const cookie of this.cookieDataList) {
      const cookieSplit = cookie.split("=");
      if (cookieSplit[0] === "robotama-cookie") this.cookieSetFlag = true;
    }

    if (this.cookieSetFlag) {
      // console.log("cookieSetFlagが立っている！Cookie同意済みUser");
    } else {
      if (this.rejectFlag) {
        // console.log("rejectFlagが立っている！Cookie-拒否User");
      } else {
        // console.log("2つのFlagが立っていない！初回Access-Userか、有効期限切れUser");
        this.popupDisplay();
      }
    }

    this.cookieAgree?.addEventListener("click", () => {
      this.cookieContainer?.classList.remove("-is-active");
      $("#cookie").attr("aria-hidden", "true");
      $("#cookie").find("a, button").attr("tabindex","-1");
      this.setCookie(this.cookieExpire);
    });

    this.cookieReject?.addEventListener("click", ()=> {
      this.cookieContainer?.classList.remove("-is-active");
      $("#cookie").attr("aria-hidden", "true");
      $("#cookie").find("a, button").attr("tabindex","-1");

      // Cookie拒否Userは、SessionStorageで管理 Sessionが切れれば、また表示される
      sessionStorage.setItem("rejectFlag", "true");
      this.deleteAllCookie();
    });

    this.cookieClose?.addEventListener("click", () => {
      $("#cookie").attr("aria-hidden", "true");
      $("#cookie").find("a, button").attr("tabindex","-1");
      this.cookieContainer?.classList.remove("-is-active");
    });
  }

  setCookie(expire: number): void {
    // const current = new Date();
    // current.setTime(current.getTime() + expire * 24 * 3600 * 1000);
    // const str = current.toUTCString();
    // document.cookie = `robotama-cookie=robotama-read; path=/; expires=${str}`;
    document.cookie = `robotama-cookie=robotama-read; path=/; expires=0`; // 有効期限をブラウザを閉じるまでに設定
  }

  deleteAllCookie(): void {
    const maxAgeZero = "max-age=0";
    for (const cookie of this.cookieDataList) {
      const cookieSplit = cookie.split("=");
      document.cookie = `${cookieSplit[0]}=; ${maxAgeZero}`;
      console.log(`${cookieSplit[0]}=; ${maxAgeZero}`);
    }
  }

  popupDisplay(): void {
    this.cookieContainer?.classList.add("-is-active");
    $("#cookie").removeAttr("aria-hidden");
    $("#cookie").find("a, button").removeAttr("tabindex");
  }

}

new Cookie();
