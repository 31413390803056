// import "core-js";
// import "picturefill";
// import ObjectFitImages from "object-fit-images"; ObjectFitImages();
// import SafetyBlank from "./modules/SafetyBlank"; SafetyBlank();
// import SwiperModules from "./modules/SwiperModules";
// import './modules/HeaderMenu';
// import './modules/FotterWave';
// import './modules/Mv';
// import './modules/Inview';
// import './modules/SmoothScroll';
//import './modules/Index';
import "./modules/Cookie";

//const page = document.querySelector('.main');
//const swiperModules = new SwiperModules();

// if(page){
//   switch(page.getAttribute('data-pages')) {
//     case 'index':
//       // swiperModules.message();
//       break;
//     case 'test':
//       $(function(){
//         $('button.button-start-quiz').html('クイズを開始');
//         $('.quiz-result').append('<div class="quiz-actions"><button class="button button-salmon button-play-again full-width">もう一度挑戦する</button></div>');
//         $('.button-play-again').on('click', function(){
//           location.reload();
//         });
//       });
//       break;
//     default:
//       break;
//   }
// }
